<content-loading-animation *ngIf="loadingDetails && !source"></content-loading-animation>

<!-- New -->
<div class="details-panel new-page" *ngIf="source && !loadingDetails">
    <!-- Header -->
    <app-details-page-header [tags]="source.resourceTags" [canEdit]="canEdit(source)" [canDisable]="canEdit(source)" (closePage)="close()">
        <zx-mediaconnect-source
            title
            [model]="source"
            [showStatusIcon]="true"
            [showStatusText]="false"
            [showLink]="false"
            [showTag]="false"
        ></zx-mediaconnect-source>
        <app-details-page-header-action-buttons
            action-buttons
            [canEdit]="canEdit(source)"
            [muted]="source.active_mute"
            (toggleMute)="toggleMute()"
            [editUrl]="editUrl()"
            [canToggle]="false"
            (refresh)="refresh()"
            [refreshing]="refreshing"
        ></app-details-page-header-action-buttons>
        <app-details-page-header-drop-down
            drop-down
            [canEdit]="canEdit(source)"
            [cloneBtn]="source.type !== 'monitor_only'"
            [add]="{
                route: [constants.urls.channels, 'new', 'source', 'mediaconnect', source.name],
                text: ('ADD_CHANNEL' | translate)
            }"
            [userPermissions]="
                !resourceTags?.length || (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
            "
            (clone)="cloneSource(source)"
            (delete)="deleteSource()"
            (muteUntil)="muteUntil($event)"
            [configBtn]="!!(source.mediaconnect_flow_id || source.medialive_channel_id)"
            (config)="config()"
            [editUrl]="editUrl()"
            [muted]="source.active_mute"
            (toggleMute)="toggleMute()"
        ></app-details-page-header-drop-down>
    </app-details-page-header>
    <!-- Content -->
    <spinner-animation *ngIf="!isWidgetFullyLoaded"></spinner-animation>
    <div class="details-content" #scrollArea>
        <div class="scroll-content">
            <ng-container *ngIf="isWidgetFullyLoaded">
                <app-layout-buttons
                    *ngIf="!isMobile"
                    [savingLayout]="savingLayout"
                    (saveLayout)="saveLayout()"
                    (resetLayout)="resetLayout()"
                    (revertLayout)="revertLayoutChanges()"
                    [layoutChanged]="layoutChanged"
                    [isLocked]="isLocked"
                    (lockChanged)="lockChanged($event)"
                ></app-layout-buttons>
                <!-- Details-section -->
                <app-details-section
                    #primaryDetailsArea
                    [(detailsList)]="primaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isPrimaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isPrimaryDetails]="true"
                    [isLocked]="isLocked"
                    (refreshPage)="refresh()"
                    class="primary"
                    [ngClass]="{ sticky: isPrimaryDetailsSectionPinned }"
                ></app-details-section>
                <app-details-section
                    #secondaryDetailsArea
                    [(detailsList)]="secondaryDetails"
                    (detailsListChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isSectionPinned)]="isSecondaryDetailsSectionPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [isLocked]="isLocked"
                    [(isSectionHidden)]="isSecondaryDetailsSectionHidden"
                    (isSectionHiddenChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [ngClass]="{
                        sticky: isSecondaryDetailsSectionPinned || (isSecondaryDetailsSectionHidden && (isPrimaryDetailsSectionPinned || isWidgetHeaderPinned)),
                        hidden: isSecondaryDetailsSectionHidden
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && (isSecondaryDetailsSectionPinned || isSecondaryDetailsSectionHidden)
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionHidden
                                ? 40
                                : -1,
                        'z-index': constants.zindexValues.second
                    }"
                >
                </app-details-section>
                <!-- Alerts -->
                <div>
                    <zx-active-states
                        [activeStates]="source.activeStates"
                        [refreshFunction]="refreshSource"
                        [canEdit]="canEdit(source)"
                        [objectName]="source.name"
                    ></zx-active-states>
                    <ngb-alert [dismissible]="false" type="info" *ngIf="source?.active_flapping && source?.is_enabled">
                        <strong>{{ "INFO" | translate }}</strong> - {{ "FLAPPING_STATE" | translate }}
                        <zx-date-time-zone [dateTime]="source.flapping" />
                    </ngb-alert>
                </div>
                <!-- Tabs -->
                <app-widget-section-header
                    [(widgetHeaders)]="widgetHeaders"
                    (widgetSelectedChange)="onWidgetSelectChange($event)"
                    [(isSectionPinned)]="isWidgetHeaderPinned"
                    (isSectionPinnedChange)="checkForUnsavedChanges(); getDetailsAreaHeights()"
                    [(isMultiSelect)]="isMultiSelect"
                    (isMultiSelectChange)="multiSelectChanged($event)"
                    [multiWidgetHeaders]="multiWidgetHeaders"
                    [isLocked]="isLocked"
                    [isMobile]="isMobile"
                    [ngClass]="{
                        sticky: isWidgetHeaderPinned
                    }"
                    [ngStyle]="{
                        'top.px':
                            isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight + secondaryDetailsAreaHeight - 1
                                : isPrimaryDetailsSectionPinned && !isSecondaryDetailsSectionPinned
                                ? primaryDetailsAreaHeight - 1
                                : !isPrimaryDetailsSectionPinned && isSecondaryDetailsSectionPinned
                                ? secondaryDetailsAreaHeight - 1
                                : -1,
                        'z-index': constants.zindexValues.first
                    }"
                >
                </app-widget-section-header>
                <!-- Content -->
                <app-widget-section
                    [(widgets)]="widgets"
                    (widgetsChange)="onWidgetsLayoutChange($event)"
                    [isMultiSelect]="isMultiSelect"
                    [isLocked]="isLocked"
                ></app-widget-section>
            </ng-container>
        </div>
    </div>
</div>

<!-- Details Panel -->
<!--<div class="details-panel" *ngIf="source">
        <div class="details-buttons">-->
<!-- Mobile Action Buttons -->
<!--<div class="d-block d-md-none float-end">
                <div>
                    <button
                        class="btn btn-outline-secondary me-2 mb-2"
                        id="actionDropdown"
                        title="{{ 'ACTIONS' | translate }}"
                        [matMenuTriggerFor]="mobileActionsMenu"
                        (click)="$event.stopPropagation()"
                    >
                        <fa-icon icon="ellipsis-v" size="1x"></fa-icon>
                    </button>
                    <div aria-labelledby="actionDropdown" class="shadow-sm">
                        <mat-menu #mobileActionsMenu="matMenu" yPosition="below">
                            <zx-mute [model]="source" [type]="'mediaconnectSources'" class="w-100" *ngIf="canEdit(source)"></zx-mute>
                            <button class="text-primary" (click)="addChannel()" title="{{ 'ADD_CHANNEL' | translate }}" mat-menu-item *ngIf="canEdit(source)">
                                <fa-icon icon="plus" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "ADD_CHANNEL" | translate }}
                            </button>
                            <button (click)="editSource(source)" class="text-primary" title="{{ 'EDIT' | translate }}" mat-menu-item *ngIf="canEdit(source)">
                                <fa-icon icon="pencil" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "EDIT" | translate }}
                            </button>
                            <button
                                (click)="cloneSource(source)"
                                class="text-primary"
                                title="{{ 'CLONE' | translate }}"
                                [disabled]="
                                    !resourceTags?.length ||
                                    (userPermissions.is_zixi_support && !userPermissions.is_zixi_admin && !userPermissions.is_zixi_support_write)
                                "
                                *ngIf="source.type !== 'monitor_only'"
                                mat-menu-item
                            >
                                <fa-icon [icon]="['far', 'clone']" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "CLONE" | translate }}
                            </button>
                            <button (click)="deleteSource()" class="text-danger" title="{{ 'DELETE' | translate }}" mat-menu-item *ngIf="canEdit(source)">
                                <fa-icon icon="trash-alt" size="sm" [fixedWidth]="true"></fa-icon>
                                {{ "DELETE" | translate }}
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>-->
