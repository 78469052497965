<div class="panel-container" id="panel-container">
    <div
        id="left-container"
        class="left-container new-left-container"
        #leftContainer
        [ngClass]="{
            rowSelected: sourceName || showInsights,
            leftSideContracted: splitterPosition === 1 && (sourceName || showInsights),
            resizing: isResizing
        }"
    >
        <!-- Title Bar -->
        <div class="title-bar pe-2">
            <div class="title">
                <h1 translate title="{{ 'SOURCES' | translate }}">
                    SOURCES
                    <small *ngIf="sourcesPagination?.pages !== sourcesPagination?.loaded">
                        {{ "LOADING" | translate }} ({{ sourcesPagination?.loaded }} {{ "OF" | translate }} {{ sourcesPagination?.pages }})
                    </small>
                </h1>
            </div>
            <div class="actions">
                <div class="form-inline">
                    <button
                        zmid="add-service"
                        type="button"
                        class="btn border-0 btn-outline-primary"
                        routerLink="/{{ urls.sources }}/new"
                        title="{{ 'ADD' | translate }}"
                    >
                        <fa-icon icon="plus" size="sm"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!sourceName && !showInsights">{{ "ADD" | translate }}</span>
                    </button>
                    <button
                        *ngIf="!(sourceName || showInsights) || splitterPosition === 3"
                        type="button"
                        class="btn border-0 btn-outline-secondary"
                        (click)="refresh()"
                        title="{{ 'REFRESH' | translate }}"
                    >
                        <fa-icon icon="sync-alt" size="sm" [spin]="refreshing"></fa-icon>
                        <span class="d-none d-sm-inline-block ms-1" *ngIf="!sourceName && !showInsights">{{ "REFRESH" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- List Panel -->
        <div id="list-panel" class="list-panel pb-0" #listPanel [ngClass]="{ rowSelected: sourceName || showInsights }">
            <div class="list-container overflow-y-hidden">
                <!-- Table -->
                <app-table-list
                    [tableName]="'source'"
                    [displayTableName]="'SOURCES' | translate"
                    [data]="allSourcesObservable | async"
                    [(tableSchema)]="tableColumnsSchema"
                    [(selectedRows)]="selectedRows"
                    (currentPage)="onPagination($event)"
                    (currentSortDirection)="onSort($event)"
                    (rowSelected)="selectRow($event)"
                    [excelReportTitle]="'SOURCES' | translate"
                    [forceCollapseWithSelection]="showInsights"
                    (selectedRowsChange)="onSelectedRowsChange($event)"
                >
                    <div class="d-flex grid-gap-2" *ngIf="!sourceName && !showInsights && selectedRows.length > 0">
                        <button
                            type="button"
                            class="btn btn-outline-green"
                            [ngClass]="{ active: showInsights }"
                            (click)="toggleInsights()"
                            title="{{ 'INSIGHTS' | translate }}"
                        >
                            <fa-icon icon="chart-scatter" size="1x"></fa-icon>
                            <span class="d-none d-sm-inline-block ms-1">{{ "INSIGHTS" | translate }}</span>
                        </button>
                        <app-zx-action-buttons
                            (multiToggleMute)="multiToggleMute($event)"
                            (multiEdit)="multiEdit()"
                            (multiEnable)="multiEnable()"
                            (multiToggleState)="multiToggleState($event)"
                            (multiDelete)="multiDelete()"
                            (multiReset)="multiReset()"
                            (disasterRecoveryClick)="disasterRecoveryClick($event)"
                            [resetButton]="true"
                            [toggleStateButton]="isShowMultiEnableDisableButton"
                            [enableButton]="isShowMultiEnableDisableButton"
                            [disasterRecovery]="multiSelectDisasterRecovery"
                        ></app-zx-action-buttons>
                    </div>
                </app-table-list>
            </div>
        </div>
    </div>
    <zx-splitter *ngIf="sourceName || showInsights" (resizing)="resizing($event)"></zx-splitter>
    <div class="new-right-container" [ngClass]="{ resizing: isResizing, rightSideContracted: splitterPosition === 3 }" *ngIf="sourceName || showInsights">
        <router-outlet *ngIf="sourceName"></router-outlet>
        <div class="insights-wrapper" *ngIf="showInsights">
            <div class="details-panel">
                <div class="details-action-bar minh-52px">
                    <div class="details-title">
                        <h2 class="ellipsis" title="{{ 'INSIGHTS' | translate }}" tourAnchor="firstSourceInsightsAnchor">
                            <button type="button" class="btn btn-round me-1" (click)="toggleInsights()" title="{{ 'BACK' | translate }}">
                                <fa-icon icon="times" size="1x" class="d-none d-lg-block"></fa-icon>
                                <fa-icon icon="chevron-left" size="1x" class="d-lg-none"></fa-icon></button
                            >{{ "INSIGHTS" | translate
                            }}<fa-icon
                                class="ms-2 tourStartButton"
                                icon="question-circle"
                                #tooltip="matTooltip"
                                matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_INSIGHTS_BY_CLICKING_HERE' | translate }}"
                                matTooltipPosition="below"
                                matTooltipClass="below"
                                size="sm"
                                (click)="tourService.start(); mixpanelService.sendEvent('start target insights feature guide')"
                            ></fa-icon>
                        </h2>
                    </div>
                    <div class="details-buttons">
                        <!-- View Radio Button -->
                        <div class="d-block float-end">
                            <label for="insightsViewOptions" class="sr-only">{{ "INSIGHTS" | translate }} {{ "VIEW" | translate }}</label>
                            <mat-button-toggle-group
                                id="insightsViewOptions"
                                name="insightsViewOption"
                                aria-label="insights view option"
                                [(ngModel)]="insightsViewOption"
                                (change)="insightsViewChange()"
                            >
                                <mat-button-toggle value="network" title="{{ 'NETWORK' | translate }}">
                                    <span>{{ "NETWORK" | translate }}</span>
                                </mat-button-toggle>
                                <mat-button-toggle value="cqa" title="{{ 'CQA' | translate }}">
                                    <span>{{ "CQA" | translate }}</span>
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                </div>
                <div class="details-content-tabs p-3" tourAnchor="secondSourceInsightsAnchor">
                    <app-list-insights
                        tourAnchor="thirdSourceInsightsAnchor"
                        [objects]="selectedRows"
                        [objectsType]="'source'"
                        [panels]="insightsPanels"
                    ></app-list-insights>
                </div>
            </div>
        </div>
    </div>
</div>
