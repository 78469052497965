<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <app-form-title [titles]="['HLS_PULL_SOURCE', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'HLS_PULL_SOURCE' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "HLS_PULL_SOURCE" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- URL -->
                    <div class="form-group">
                        <label for="url" [ngClass]="{ 'is-invalid': form.submitted && url.errors }">
                            {{ "URL" | translate }}
                            <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="string"
                            id="url"
                            name="url"
                            placeholder="{{ 'https://example.com/index.m3u8' }}"
                            [(ngModel)]="source.url"
                            pattern="^https?:\/\/.*\/.*\.m3u8(\?.*)?$"
                            #url="ngModel"
                            class="form-control"
                            (change)="streamInputChange()"
                            [ngClass]="{ 'is-invalid': form.submitted && url.errors }"
                            [required]="!!skipPlaylistVerification"
                        />
                        <div *ngIf="form.submitted && url.invalid" class="invalid-feedback">
                            <div *ngIf="url.errors.pattern">{{ "URL" | translate }} {{ "MUST_BE_AN_HLS_URL" | translate }}.</div>
                            <div *ngIf="url.errors.required">{{ "URL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                        <div>
                            <small *ngIf="!skipPlaylistVerification" [innerHTML]="'LOAD_PLAYLIST_STREAMS_NOTE' | translate"></small>
                            <small *ngIf="!!skipPlaylistVerification" [innerHTML]="'SET_PLAYLIST_STREAM_NOTE' | translate"></small>
                        </div>

                        <div class="flex-gap">
                            <button
                                type="button"
                                class="btn btn-outline-primary mt-2"
                                (click)="btnLoadHLSPlaylist()"
                                [disabled]="selectedStreamLoading || skipPlaylistVerification"
                            >
                                {{ "LOAD_PLAYLIST_STREAMS" | translate }}
                            </button>

                            <!-- Skip Playlist Verification Checkbox-->
                            <div class="form-group">
                                <div class="form-check">
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        id="skipPlaylistVerification"
                                        name="skipPlaylistVerification"
                                        [(ngModel)]="skipPlaylistVerification"
                                        (change)="streamInputChange()"
                                    />
                                    <label class="form-check-label" for="skipPlaylistVerification">{{ "SKIP_PLAYLIST_VERIFICATION" | translate }}</label>
                                </div>
                            </div>
                        </div>

                        <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="HLSStreamLoadError" class="mt-3 mb-3">
                            <strong>{{ "ERROR" | translate }}</strong
                            >:&nbsp;{{ HLSStreamLoadError }}.
                        </ngb-alert>
                    </div>

                    <!-- Stream selection -->
                    <div class="form-group" *ngIf="!skipPlaylistVerification">
                        <label for="selectedStreamCombo">{{ "HLS_MEDIA_STREAM_URL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        <ng-select
                            id="selectedStreamCombo"
                            name="selectedStreamCombo"
                            [items]="HLSPlaylist.streams"
                            [clearable]="false"
                            class="form-control"
                            [required]="!skipPlaylistVerification"
                            bindValue="url"
                            bindLabel="url"
                            placeholder="{{ 'SELECT_HLS_STREAM_URL' | translate }}"
                            [(ngModel)]="selectedStream"
                            #selectedStreamCombo="ngModel"
                            [loading]="selectedStreamLoading"
                            [ngClass]="{ 'is-invalid': form.submitted && selectedStreamCombo.errors }"
                        >
                        </ng-select>
                        <div *ngIf="selectedStreamCombo.invalid" class="invalid-feedback">
                            <div *ngIf="selectedStreamCombo.errors.required">{{ "HLS_MEDIA_STREAM_URL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <!-- Stream selection -->
                    <div class="form-group" *ngIf="!!skipPlaylistVerification">
                        <label for="selectedStreamComboInput">{{ "HLS_MEDIA_STREAM_URL" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon></label>
                        <input
                            id="selectedStreamComboInput"
                            name="selectedStreamComboInput"
                            class="form-control"
                            [required]="!!skipPlaylistVerification"
                            bindValue="url"
                            bindLabel="url"
                            placeholder="{{ 'WRITE_HLS_STREAM_URL_MANUALLY' | translate }}"
                            [(ngModel)]="source.url"
                            #selectedStreamComboInput="ngModel"
                            [ngClass]="{ 'is-invalid': form.submitted && selectedStreamComboInput.errors }"
                            [readonly]="true"
                        />
                        <div *ngIf="selectedStreamComboInput.invalid" class="invalid-feedback">
                            <div *ngIf="selectedStreamComboInput.errors.required">
                                {{ "HLS_MEDIA_STREAM_URL" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>

                    <!-- Buffer Size -->
                    <div class="form-group">
                        <label for="buffer_size" [ngClass]="{ 'is-invalid': form.submitted && buffer_size.errors }"
                            >{{ "BUFFER_SIZE" | translate }} [ms]<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="number"
                            id="buffer_size"
                            name="buffer_size"
                            placeholder="{{ 'BUFFER_SIZE' | translate }}"
                            class="form-control"
                            [min]="0"
                            pattern="^\d+$"
                            [(ngModel)]="source.latency"
                            [ngClass]="{ 'is-invalid': form.submitted && buffer_size.errors }"
                            required
                            #buffer_size="ngModel"
                        />
                        <div *ngIf="buffer_size.invalid" class="invalid-feedback">
                            <div *ngIf="buffer_size.errors.required">{{ "BUFFER_SIZE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            <div *ngIf="buffer_size.errors.pattern">
                                {{ "BUFFER_SIZE" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate | lowercase }}.
                            </div>
                        </div>
                    </div>

                    <!-- Inject SCTE on CUE -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="inject_scte_on_cue"
                                name="inject_scte_on_cue"
                                [(ngModel)]="source.inject_scte_on_cue"
                            />
                            <label class="form-check-label" for="inject_scte_on_cue">
                                {{ "HLS_PULL_INJECT_SCTE_CUE" | translate }}
                            </label>
                        </div>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'SOURCE' | translate }} {{ 'CONFIGURATION' | translate }}">
                            {{ "SOURCE" | translate }} {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            [ngModel]="source.broadcaster_cluster_id"
                            [required]="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                {{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- PID Mapping Profile -->
                    <div class="form-group">
                        <label for="pidMappingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                            >{{ "PID_MAPPING_PROFILE" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-pid-mapping-profile-select
                            name="pidMappingProfile"
                            [(model)]="source.pid_mapping_profile_id"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.pidMappingProfile?.errors }"
                        ></zx-pid-mapping-profile-select>
                        <div *ngIf="form.controls.pidMappingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.pidMappingProfile?.errors.required">
                                {{ "PID_MAPPING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>

                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <app-form-section-title [title]="'INITIAL_STATE' | translate"></app-form-section-title>

                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showBilling]="true"
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
