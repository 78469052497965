<content-loading-animation *ngIf="loading"></content-loading-animation>
<form id="form" novalidate #form="ngForm" (ngSubmit)="form.valid && !nameControl.invalid && !tagsControl.invalid && onSubmit()" *ngIf="!loading">
    <app-form-title [titles]="['TRANSCODED', 'SOURCE']" [isEdit]="isEdit" [isClone]="isClone"></app-form-title>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <app-form-section-title [title]="'INFO' | translate"></app-form-section-title>
                    <!-- Name -->
                    <app-input-generic-field
                        zmid="form_name"
                        duplicateName="sourceNames"
                        [formControl]="nameControl"
                        [textFieldName]="'NAME' | translate"
                        [disabled]="isEdit || saving"
                        [isParentFormSubmitted]="form.submitted"
                        type="text"
                    ></app-input-generic-field>

                    <!-- Access Tags -->
                    <zx-access-tags-select
                        zmid="form_access-tags"
                        [formControl]="tagsControl"
                        [label]="'TAGS' | translate"
                        name="resource_tag_ids"
                        [isParentFormSubmitted]="form.submitted"
                    ></zx-access-tags-select>

                    <!-- Alerting Profile -->
                    <div class="form-group">
                        <label for="alertingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }">{{
                            "ALERTING_PROFILE" | translate
                        }}</label>
                        <zx-alerting-profile-select
                            name="alertingProfile"
                            [(model)]="source.alertingProfile"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.alertingProfile?.errors }"
                        ></zx-alerting-profile-select>
                    </div>
                    <!-- Location -->
                    <div class="form-group">
                        <label for="location_text"
                            >{{ "LOCATION" | translate
                            }}<fa-icon icon="info-circle" [ngbTooltip]="LocationContent" triggers="hover click" [closeDelay]="500"></fa-icon>
                            <ng-template #LocationContent>{{ "TOOLTIP.SOURCE_LOCATION" | translate }}</ng-template></label
                        >
                        <zx-location-search [(model)]="source.location.address"></zx-location-search>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'INPUT' | translate }}">
                            {{ "INPUT" | translate }}
                        </h3>
                    </div>

                    <!-- Source -->
                    <div class="form-group">
                        <label for="transcodeSource" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodeSource?.errors }"
                            >{{ "SOURCE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-source-select
                            name="transcodeSource"
                            [(model)]="source.transcode_source_id"
                            [ngModel]="source.transcode_source_id"
                            [filter]="transcodedSourceFilter"
                            [required]="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodeSource?.errors }"
                        ></zx-source-select>
                        <div *ngIf="form.controls.transcodeSource?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.transcodeSource?.errors.required">{{ "SOURCE" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>

                    <div class="form-section-title">
                        <h3 class="section-title" title="{{ 'CONFIGURATION' | translate }}">
                            {{ "CONFIGURATION" | translate }}
                        </h3>
                    </div>

                    <!-- Ingest Cluster -->
                    <div class="form-group">
                        <label for="broadcaster_cluster_id" [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            >{{ "INGEST_CLUSTER" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-cluster-select
                            name="broadcaster_cluster_id"
                            [(model)]="source.broadcaster_cluster_id"
                            [ngModel]="source.broadcaster_cluster_id"
                            [required]="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.broadcaster_cluster_id?.errors }"
                            (modelChange)="clusterSelectionChange(source.broadcaster_cluster_id, true)"
                        ></zx-cluster-select>
                        <div *ngIf="form.controls.broadcaster_cluster_id?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.broadcaster_cluster_id?.errors.required">
                                {{ "INGEST_CLUSTER" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Target Broadcaster/s -->
                    <div class="form-group">
                        <label for="target_broadcaster_id" [ngClass]="{ 'is-invalid': form.submitted && targetBroadcasters.errors }"
                            >{{ "TARGET_BROADCASTER/S" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <ng-select
                            id="target_broadcaster_id"
                            name="target_broadcaster_id"
                            [items]="targetBXs"
                            [clearable]="false"
                            bindValue="id"
                            bindLabel="name"
                            groupBy="type"
                            placeholder="{{ 'SELECT_BROADCASTER/S' | translate }}"
                            [(ngModel)]="source.target_broadcaster_id"
                            [ngClass]="{ 'form-control is-invalid': form.submitted && targetBroadcasters.errors }"
                            [disabled]="!source.broadcaster_cluster_id"
                            #targetBroadcasters="ngModel"
                            [loading]="targetBXsLoading"
                            required
                        >
                            <ng-template ng-optgroup-tmp let-item="item">
                                <span>{{ "SELECT_SPECIFIC_BROADCASTER" | translate }}</span>
                            </ng-template>
                            <ng-template ng-label-tmp let-item="item">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span *ngIf="item.type"><zx-status-icon [model]="item" class="me-1"></zx-status-icon>{{ item.name }}</span>
                                <span *ngIf="!item.type">{{ item.name }}</span>
                            </ng-template>
                        </ng-select>
                        <div *ngIf="targetBroadcasters.invalid" class="invalid-feedback">
                            <div *ngIf="targetBroadcasters.errors.required">{{ "TARGET_BROADCASTER/S" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                        </div>
                    </div>
                    <!-- Transcoding Profiles -->
                    <div class="form-group">
                        <label for="transcodingProfile" [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                            >{{ "TRANSCODING_PROFILE" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                            ><app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <zx-transcoding-profile-select
                            name="transcodingProfile"
                            [(model)]="transcodeProfile"
                            [required]="true"
                            [ngClass]="{ 'is-invalid': form.submitted && form.controls.transcodingProfile?.errors }"
                            (change)="onTranscodingProfileChanged($event)"
                        ></zx-transcoding-profile-select>
                        <div *ngIf="form.controls.transcodingProfile?.invalid" class="invalid-feedback">
                            <div *ngIf="form.controls.transcodingProfile?.errors.required">
                                {{ "TRANSCODING_PROFILE" | translate }} {{ "IS_REQUIRED" | translate }}.
                            </div>
                        </div>
                    </div>
                    <!-- Closed Caption -->
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="closed_cap" name="closed_cap" [(ngModel)]="source.copy_closed_captions" />
                            <label class="form-check-label" for="closed_cap">{{ "CLOSED_CAPTION_PRESERVE" | translate }}</label>
                        </div>
                    </div>
                    <!-- Crop -->
                    <div class="form-group">
                        <div class="form-check">
                            <input type="checkbox" class="form-check-input" id="crop" name="crop" [(ngModel)]="crop" />
                            <label class="form-check-label" for="crop"
                                >{{ "CROP" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                            ></label>
                        </div>
                    </div>
                    <fieldset class="bordered crop-settings" *ngIf="crop === true">
                        <legend>
                            {{ "CROP_SETTINGS" | translate }}
                            <app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon>
                        </legend>
                        <!-- Units -->
                        <div class="form-group">
                            <label for="crop_mode" class="minw-50px">{{ "UNITS" | translate }}</label>
                            <mat-button-toggle-group name="crop_mode" aria-label="crop mode" [(ngModel)]="cropMode">
                                <mat-button-toggle value="pixels">{{ "PIXELS" | translate | titlecase }}</mat-button-toggle>
                                <mat-button-toggle value="percentages">{{ "PERCENTAGES" | translate }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <!-- Pixels -->
                        <fieldset *ngIf="cropMode === 'pixels'">
                            <legend class="sr-only">{{ "PIXEL_SETTINGS" | translate }}</legend>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="pixel_crop_left"
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_left.errors }"
                                                >{{ "X_LEFT" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="pixel_crop_left"
                                                name="pixel_crop_left"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="pixelCropLeft"
                                                [min]="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_left.errors }"
                                                #pixel_crop_left="ngModel"
                                            />
                                            <div *ngIf="pixel_crop_left.invalid" class="invalid-feedback">
                                                <div *ngIf="pixel_crop_left.errors.required">{{ "X_LEFT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="pixel_crop_left.errors.pattern">
                                                    {{ "X_LEFT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_left.errors }">{{
                                                "PX" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="pixel_crop_top"
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_top.errors }"
                                                >{{ "Y_TOP" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="pixel_crop_top"
                                                name="pixel_crop_top"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="pixelCropTop"
                                                [min]="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_top.errors }"
                                                #pixel_crop_top="ngModel"
                                            />
                                            <div *ngIf="pixel_crop_top.invalid" class="invalid-feedback">
                                                <div *ngIf="pixel_crop_top.errors.required">{{ "Y_TOP" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="pixel_crop_top.errors.pattern">
                                                    {{ "Y_TOP" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_top.errors }">{{
                                                "PX" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="pixel_crop_width"
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_width.errors }"
                                                >{{ "WIDTH" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="pixel_crop_width"
                                                name="pixel_crop_width"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="pixelCropWidth"
                                                [min]="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_width.errors }"
                                                #pixel_crop_width="ngModel"
                                            />
                                            <div *ngIf="pixel_crop_width.invalid" class="invalid-feedback">
                                                <div *ngIf="pixel_crop_width.errors.required">{{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="pixel_crop_width.errors.pattern">
                                                    {{ "WIDTH" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_width.errors }">{{
                                                "PX" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="pixel_crop_height"
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_height.errors }"
                                                >{{ "HEIGHT" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="pixel_crop_height"
                                                name="pixel_crop_height"
                                                placeholder="{{ 'PIXELS' | translate }}"
                                                pattern="^\d+$"
                                                [(ngModel)]="pixelCropHeight"
                                                [min]="0"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_height.errors }"
                                                #pixel_crop_height="ngModel"
                                            />
                                            <div *ngIf="pixel_crop_height.invalid" class="invalid-feedback">
                                                <div *ngIf="pixel_crop_height.errors.required">{{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                                                <div *ngIf="pixel_crop_height.errors.pattern">
                                                    {{ "HEIGHT" | translate }} {{ "MUST_BE_A_POSITIVE_INTEGER" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && pixel_crop_height.errors }">{{
                                                "PX" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <!-- Percentages -->
                        <fieldset *ngIf="cropMode === 'percentages'">
                            <legend class="sr-only">{{ "PERCENTAGE_SETTINGS" | translate }}</legend>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="percentage_crop_left"
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_left.errors }"
                                                >{{ "X_LEFT" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="percentage_crop_left"
                                                name="percentage_crop_left"
                                                placeholder="{{ 'PERCENTAGE' | translate }}"
                                                [(ngModel)]="percentageCropLeft"
                                                [min]="0"
                                                [max]="100"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_left.errors }"
                                                #percentage_crop_left="ngModel"
                                            />
                                            <div *ngIf="percentage_crop_left.invalid" class="invalid-feedback">
                                                <div *ngIf="percentage_crop_left.errors.required">
                                                    {{ "X_LEFT" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="percentage_crop_left.errors.min || percentage_crop_left.errors.max">
                                                    {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_left.errors }">{{
                                                "%" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="percentage_crop_top"
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_top.errors }"
                                                >{{ "Y_TOP" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="percentage_crop_top"
                                                name="percentage_crop_top"
                                                placeholder="{{ 'PERCENTAGE' | translate }}"
                                                [(ngModel)]="percentageCropTop"
                                                [min]="0"
                                                [max]="100"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_top.errors }"
                                                #percentage_crop_top="ngModel"
                                            />
                                            <div *ngIf="percentage_crop_top.invalid" class="invalid-feedback">
                                                <div *ngIf="percentage_crop_top.errors.required">
                                                    {{ "Y_TOP" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="percentage_crop_top.errors.min || percentage_crop_top.errors.max">
                                                    {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_top.errors }">{{
                                                "%" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="percentage_crop_width"
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_width.errors }"
                                                >{{ "WIDTH" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="percentage_crop_width"
                                                name="percentage_crop_width"
                                                placeholder="{{ 'PERCENTAGE' | translate }}"
                                                [(ngModel)]="percentageCropWidth"
                                                [min]="0"
                                                [max]="100"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_width.errors }"
                                                #percentage_crop_width="ngModel"
                                            />
                                            <div *ngIf="percentage_crop_width.invalid" class="invalid-feedback">
                                                <div *ngIf="percentage_crop_width.errors.required">
                                                    {{ "WIDTH" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="percentage_crop_width.errors.min || percentage_crop_width.errors.max">
                                                    {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_width.errors }">{{
                                                "%" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="input-group">
                                        <div class="input-group-prepend w-52px">
                                            <label
                                                class="me-2 mt-2"
                                                for="percentage_crop_height"
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_height.errors }"
                                                >{{ "HEIGHT" | translate }}</label
                                            >
                                        </div>
                                        <div class="input-group-div">
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="percentage_crop_height"
                                                name="percentage_crop_height"
                                                placeholder="{{ 'PERCENTAGE' | translate }}"
                                                [(ngModel)]="percentageCropHeight"
                                                [min]="0"
                                                [max]="100"
                                                required
                                                [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_height.errors }"
                                                #percentage_crop_height="ngModel"
                                            />
                                            <div *ngIf="percentage_crop_height.invalid" class="invalid-feedback">
                                                <div *ngIf="percentage_crop_height.errors.required">
                                                    {{ "HEIGHT" | translate }} {{ "IS_REQUIRED" | translate }}.
                                                </div>
                                                <div *ngIf="percentage_crop_height.errors.min || percentage_crop_height.errors.max">
                                                    {{ "MUST_BE_BETWEEN_0_AND_100" | translate }}.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-group-append">
                                            <label class="ms-2" [ngClass]="{ 'is-invalid': form.submitted && percentage_crop_height.errors }">{{
                                                "%" | translate
                                            }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </fieldset>
                    <!-- GPU -->
                    <div class="form-group">
                        <div class="input-group align-items-center">
                            <div class="input-group-prepend">
                                <div class="input-group">
                                    <div class="form-check form-check-inline">
                                        <input
                                            type="checkbox"
                                            class="form-check-input"
                                            id="multiGpu"
                                            name="multiGpu"
                                            [(ngModel)]="multiGpu"
                                            [disabled]="source.transcode_encoder !== 'any'"
                                        />
                                        <label class="form-check-label" for="multiGpu"
                                            >{{ "ASSIGN_TO_GPU" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                                        ></label>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group-div">
                                <ng-select
                                    name="gpu_id"
                                    class="form-control"
                                    [searchable]="false"
                                    [clearable]="false"
                                    [(ngModel)]="source.gpu_id"
                                    [items]="[0, 1, 2, 3]"
                                    [disabled]="!multiGpu"
                                ></ng-select>
                            </div>
                        </div>
                        <small class="mt-1">
                            {{ "CHOOSING_ENCODER_WARNING" | translate }}
                        </small>
                    </div>
                    <!-- Pad to CBR -->
                    <div class="form-group">
                        <label for="transcode_cbr_kbps"
                            >{{ "PAD_TO_CBR" | translate }} [kbps]<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            type="number"
                            id="transcode_cbr_kbps"
                            name="transcode_cbr_kbps"
                            placeholder="{{ 'SET_CBR_BITRATE' | translate }}"
                            class="form-control"
                            [(ngModel)]="source.transcode_cbr_kbps"
                        />
                        <small>{{ "LEAVE_BLANK_FOR_VBR" | translate }}</small>
                    </div>
                    <!-- Max DTS-PCR Delta -->
                    <div class="form-group" *ngIf="source.transcode_cbr_kbps">
                        <label for="max_dts_pcr_delta_ms"
                            >{{ "MAX_DTS_PCR_DELTA" | translate }}<app-restart-required-icon *ngIf="isEdit"></app-restart-required-icon
                        ></label>
                        <input
                            [disabled]="!source.transcode_cbr_kbps"
                            type="number"
                            id="max_dts_pcr_delta_ms"
                            name="max_dts_pcr_delta_ms"
                            placeholder="{{ 'SET_CBR_BITRATE' | translate }}"
                            class="form-control"
                            [(ngModel)]="source.max_dts_pcr_delta_ms"
                        />
                    </div>
                    <!-- PID Changes -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="monitor_pids_change"
                                name="monitor_pids_change"
                                [(ngModel)]="source.monitor_pids_change"
                            />
                            <label class="form-check-label" for="monitor_pids_change">{{ "ALERT_ON_PID_CHANGES" | translate }}</label>
                        </div>
                    </div>
                    <!-- SCTE-35 Log -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="log_scte"
                                name="log_scte"
                                (change)="!source.process_scte_reports ? (source.report_scte_warnings = false) : false"
                                [(ngModel)]="source.process_scte_reports"
                            />
                            <label class="form-check-label" for="log_scte">{{ "LOG_SCTE_35" | translate }}</label>
                        </div>
                        <small>{{ "SCTE_VERSION_AND_CLUSTER_CONFIGURATION_REQUIRED" | translate }}</small>
                    </div>
                    <!-- SCTE-35 Alert -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="alert_scte"
                                name="alert_scte"
                                [disabled]="!source.process_scte_reports"
                                [(ngModel)]="source.report_scte_warnings"
                            />
                            <label class="form-check-label" for="alert_scte">{{ "ENABLE_SCTE_35_ALERTS" | translate }}</label>
                        </div>
                        <small>{{ "REQUIRES_SCTE_35_LOG" | translate }}</small>
                    </div>
                    <!-- Common Fields -->
                    <app-source-form-common-fields [form]="form" [isEdit]="isEdit" [source]="source"></app-source-form-common-fields>
                    <!-- ignoreTranscodeThresholds -->
                    <div class="form-group">
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="ignoreTranscodeThresholds"
                                name="ignoreTranscodeThresholds"
                                [(ngModel)]="source.ignore_transcode_thresholds"
                            />
                            <label class="form-check-label" for="ignoreTranscodeThresholds">{{ "IGNORE_TRANSCODE_THRESHOLDS" | translate }}</label>
                        </div>
                    </div>
                    <!-- State -->
                    <section id="state" *ngIf="!isEdit">
                        <div class="form-section-title">
                            <h3 class="section-title" title="{{ 'INITIAL_STATE' | translate }}">
                                {{ "INITIAL_STATE" | translate }}
                            </h3>
                        </div>
                        <!-- Enabled -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="is_enabled" name="is_enabled" [(ngModel)]="startDisabled" />
                                <label class="form-check-label" for="is_enabled">{{ "DISABLED" | translate }}</label>
                            </div>
                        </div>
                        <!-- Muted -->
                        <div class="form-group">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" id="muted" name="muted" [(ngModel)]="source.muted" />
                                <label class="form-check-label" for="muted">{{ "MUTED" | translate }}</label>
                            </div>
                        </div>
                    </section>
                    <!-- Advanced -->
                    <app-advanced-section-form
                        [showAutopullBilling]="true"
                        [showAutoMTU]="true"
                        [model]="source"
                        [form]="form"
                        [isEdit]="isEdit"
                        [showFrozenVideoMinimalDuration]="true"
                        [showBlancVideoMinimalDuration]="true"
                        [showForceIFrames]="true"
                        [showTranscodeEncoder]="true"
                        [showHtmlOverlayUrl]="true"
                        (modelChange)="disableGpuField()"
                    ></app-advanced-section-form>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary float-start" (click)="back()" *ngIf="!isEdit && !isClone" [disabled]="saving">
                            <fa-icon icon="chevron-left" size="sm"></fa-icon>
                            {{ "BACK" | translate }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary ms-2"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && (form.invalid || nameControl.invalid || tagsControl.invalid) }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
